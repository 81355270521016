import { FormItem } from '@/interfaces/lib/model';
import {
  CompositionId,
  LawId,
  PlanId,
  RegulationId,
  RoleId,
  Section2GHSCategoryId,
  Section2GHSHazardId,
  Section2GHSItemId,
  StatusId,
  TemparatureUnitId,
} from '@/lib/enum';

export const unitList = [
  { text: '%', value: '%' },
  { text: 'ppm', value: 'ppm' },
  { text: 'mg/m3', value: 'mg/m3' },
  { text: '', value: '' },
];

export const prices: Array<FormItem> = PlanId.values().map((x: PlanId) => {
  return {
    value: x.value,
    text: x.text,
    disabled: x.value === PlanId.FREE.value ? true : false,
  };
});

export const statusList: Array<FormItem> = [
  {
    value: StatusId.UPLOADING.value,
    text: StatusId.UPLOADING.text,
    disabled: true,
  },
  {
    value: StatusId.ASSORT_DONE.value,
    text: StatusId.ASSORT_DONE.text,
    disabled: true,
  },
  {
    value: StatusId.FIRST_CHECK_PROCESSING.value,
    text: StatusId.FIRST_CHECK_PROCESSING.text,
    disabled: true,
  },
  {
    value: StatusId.SECOND_CHECK_PROCESSING.value,
    text: StatusId.SECOND_CHECK_PROCESSING.text,
    disabled: true,
  },
  {
    value: StatusId.SECOND_CHECK_DONE.value,
    text: StatusId.SECOND_CHECK_DONE.text,
    disabled: true,
  },
  { value: StatusId.ERROR.value, text: StatusId.ERROR.text, disabled: true },
  {
    value: StatusId.ERROR_RECONFIRMATION.value,
    text: StatusId.ERROR_RECONFIRMATION.text,
    disabled: true,
  },
];

type PdfDisplayOptionValue = 'default' | 'max' | 'min' | 'none';
export const pdfDisplayOptions: Array<{
  value: PdfDisplayOptionValue;
  name: string;
}> = [
  { value: 'none', name: 'なし' },
  { value: 'default', name: '標準' },
  { value: 'max', name: '拡大' },
  { value: 'min', name: '縮小' },
];

export const roleList: Array<FormItem> = [
  { value: RoleId.USER_VIEWER.value, text: RoleId.USER_VIEWER.text },
  { value: RoleId.USER_EDITOR.value, text: RoleId.USER_EDITOR.text },
  { value: RoleId.USER_ADMIN.value, text: RoleId.USER_ADMIN.text },
];

export const signalWordsList: Array<FormItem> = [
  { value: 'なし', text: 'なし' },
  { value: '警告 ; Warning', text: '警告 ; Warning' },
  { value: '危険 ; Danger', text: '危険 ; Danger' },
];

export const textFormList: Array<FormItem> = [{ value: '-', text: '-' }];

export const compositionList: Array<FormItem> = [
  { value: CompositionId.DEFAULT.value, text: CompositionId.DEFAULT.text },
  {
    value: CompositionId.SUBSTANCE.value,
    text: CompositionId.SUBSTANCE.text,
  },
  {
    value: CompositionId.MIXTURES.value,
    text: CompositionId.MIXTURES.text,
  },
  {
    value: CompositionId.UVCB.value,
    text: CompositionId.UVCB.text,
  },
];

export const skinProtectionList: Array<FormItem> = [
  { value: '-', text: '-' },
  { value: 'あり', text: 'あり' },
];

export const materialNameList: Array<FormItem> = [
  { value: '物質選択なし', text: '物質選択なし' },
];

export const section8ACGIHCateoryList: Array<FormItem> = [
  { value: 0, text: '-' },
  { value: 100, text: 'TLV-TWA' },
  { value: 101, text: 'TLV-STEL' },
  { value: 102, text: 'C(Ceiling)' },
];

export const section8DNELDMELCateoryUserList: Array<FormItem> = [
  { value: 0, text: '-' },
  { value: 200, text: '作業者 ; Worker' },
  { value: 201, text: '消費者 ; Consumer' },
  { value: 202, text: 'その他 ; Other' },
];

export const section8DNELDMELCateoryTermList: Array<FormItem> = [
  { value: 0, text: '-' },
  { value: 300, text: 'Long-term' },
  { value: 301, text: 'Short-term' },
];

export const section8DNELDMELCateoryDustRouteList: Array<FormItem> = [
  { value: 0, text: '-' },
  { value: 400, text: '経皮 ; Dermal' },
  { value: 401, text: '吸入 ; Inhalation' },
  { value: 402, text: '経口 ; Oral' },
  { value: 403, text: 'その他 ; Other' },
];

export const section8DNELDMELCateoryMeasurementList: Array<FormItem> = [
  { value: 0, text: '-' },
  { value: 500, text: 'Systemic' },
  { value: 501, text: 'Local' },
];

export const section8JSOHCategoryConcentrationList: Array<FormItem> = [
  { value: 0, text: '-' },
  {
    value: 600,
    text: '許容濃度 ; Allowable Concentration',
  },
  {
    value: 601,
    text: '最大許容濃度 ; Maximum Permissible Concentration ; Ceiling',
  },
];

export const section8JSOHCategoryDustList: Array<FormItem> = [
  { value: 0, text: '-' },
  {
    value: 701,
    text: '第1種粉塵 ; Class1',
  },
  {
    value: 702,
    text: '第2種粉塵 ; Class2',
  },
  {
    value: 703,
    text: '第3種粉塵 ; Class3',
  },
];

export const section8JSOHCategoryDustRespirableList: Array<FormItem> = [
  { value: 0, text: '-' },
  {
    value: 800,
    text: '吸入性粉じん ; Respirable dust',
  },
  {
    value: 801,
    text: '総粉じん ; Total',
  },
  {
    value: 802,
    text: 'その他 ; Other',
  },
];

export const temparatureUnitList: Array<{
  value: number;
  text: string;
}> = [
  ...TemparatureUnitId.values()
    .filter((item) => item.value != 0)
    .map((item) => ({
      text: item.text,
      value: item.value,
    })),
];

export const section9InequalityList: Array<FormItem> = [
  { value: 1, text: 'と等しい(=)' },
  { value: 2, text: '以上(≧)' },
  { value: 3, text: 'より大きい(>)' },
  { value: 4, text: '以下(≦)' },
  { value: 5, text: '未満(<)' },
  { value: 6, text: '以上(≧)、以下(≦)' },
  { value: 7, text: '以上(≧)、未満(<)' },
  { value: 8, text: 'より大きい(>)、以下(≦)' },
  { value: 9, text: 'より大きい(>)、未満(<)' },
];

export const section15TypeList: Array<FormItem> = [
  { value: true, text: '該当する' },
  { value: false, text: '該当しない（非該当）' },
];

export const concentrationUnitList: Array<FormItem> = [
  { value: '%', text: '%' },
];

export const concentrationInequalityList: Array<FormItem> = [
  { value: 1, text: 'と等しい(=)' },
  { value: 2, text: '以上(≧)' },
  { value: 3, text: 'より大きい(>)' },
  { value: 4, text: '以下(≦)' },
  { value: 5, text: '未満(<)' },
  { value: 6, text: '以上(≧)、以下(≦)' },
  { value: 7, text: '以上(≧)、未満(<)' },
  { value: 8, text: 'より大きい(>)、以下(≦)' },
  { value: 9, text: 'より大きい(>)、未満(<)' },
];

export const section2HazardList: Array<{
  value: number;
  text: string;
  itemId: number;
}> = [
  ...Section2GHSHazardId.values().map((item) => ({
    text: item.text,
    value: item.value,
    itemId: item.itemId,
  })),
];

export const section2GHSCategoryList: Array<{
  value: number;
  text: string;
  itemId: number;
}> = [
  ...Section2GHSCategoryId.values().map((item) => ({
    text: item.text,
    value: item.value,
    itemId: item.itemId,
  })),
];

export const section2GHSItemList: Array<{
  value: number;
  text: string;
}> = [
  ...Section2GHSItemId.values().map((item) => ({
    text: item.text,
    value: item.value,
  })),
];

export const lawList = LawId.values().map((x: LawId) => {
  return {
    value: x.value,
    text: x.text,
  };
});

export const regulation1List = RegulationId.values()
  .filter((v) => ((v.value / 100000000) | 0) === 2)
  .map((x: RegulationId) => {
    return {
      value: x.value,
      text: x.text,
      lawId: x.lawId,
    };
  });

export const regulation2List = RegulationId.values()
  .filter((v) => ((v.value / 100000000) | 0) === 3)
  .map((x: RegulationId) => {
    return {
      value: x.value,
      text: x.text,
      lawId: x.lawId,
    };
  });

export const regulation3List = RegulationId.values()
  .filter((v) => ((v.value / 100000000) | 0) === 4)
  .map((x: RegulationId) => {
    return {
      value: x.value,
      text: x.text,
      lawId: x.lawId,
    };
  });

export const regulation4List = RegulationId.values()
  .filter((v) => ((v.value / 100000000) | 0) === 5)
  .map((x: RegulationId) => {
    return {
      value: x.value,
      text: x.text,
      lawId: x.lawId,
    };
  });
